// Framework
import pop from './components';

// Components
import ArcoroCareers from './components/arcoro-careers';
import CardsCarousel from './components/cards-carousel';
import CardsListing from './components/cards-listing';
import Form from './components/form';
import FeaturedTestimonials from './components/featured-testimonials';
import FeaturedVideos from './components/featured-videos';
import FixedCta from './components/fixed-cta';
import FormField from './components/form-field';
import Header from './components/header';
import Hotspots from './components/hotspots';
import HoverCallouts from './components/hover-callouts';
import LocationsMap from './components/locations-map';
import LinksHero from './components/links-hero';
import Modal from './components/modal';
import PaylocityCareers from './components/paylocity-careers';
import PrimaryHero from './components/primary-hero';
import ScrollingSidekick from './components/scrolling-sidekick';
import TestimonialCards from './components/testimonial-cards';
import Waves from './components/waves';
import YoutubeVideo from './components/youtube-video';

// Helpers
import { getYoutubeId, setGclid } from './utilities';

// Define map of component handles to component classes
const classMap = {
    'arcoro-careers': ArcoroCareers,
    'cards-carousel': CardsCarousel,
    'cards-listing': CardsListing,
    form: Form,
    'featured-testimonials': FeaturedTestimonials,
    'featured-videos': FeaturedVideos,
    'fixed-cta': FixedCta,
    'form-field': FormField,
    header: Header,
    hotspots: Hotspots,
    'hover-callouts': HoverCallouts,
    'locations-map': LocationsMap,
    'links-hero': LinksHero,
    modal: Modal,
    'paylocity-careers': PaylocityCareers,
    'primary-hero': PrimaryHero,
    'scrolling-sidekick': ScrollingSidekick,
    'testimonial-cards': TestimonialCards,
    waves: Waves,
    'youtube-video': YoutubeVideo,
};

// Define all actions/commands that components pub/sub
const actions = {
    // Action events
    lockScroll: 'lock-scroll',
    unlockScroll: 'unlock-scroll',
    openModal: 'open-modal',
    closeModal: 'close-modal',
    loadModal: 'load-modal',
    showFieldError: 'show-field-error',
};

// Event handler functions
function handleDOMConentLoaded() {
    function cb({ events }) {
        function handleBodyClick(e) {
            const link = e.target.closest('a');

            if (!link) return;

            // Anchors
            if (link.matches('[href="#"]')) {
                e.preventDefault();

                const target = document.querySelector(link.href);

                if (target) {
                    target.scrollIntoView({ behavior: 'smooth' });
                }
            }

            // Videos
            if (link.matches('[href*="youtube.com"]') && !link.dataset.passthrough) {
                e.preventDefault();

                const videoId = link.href.split('v=')[1];

                fetch(`/json/youtube-video?${new URLSearchParams({ videoId })}`, {
                    headers: { Accept: 'application/json' },
                })
                    .then(res => res.json().then(json => ({
                        status: res.status,
                        ...json,
                    })))
                    .then(({
                        status,
                        markup = '',
                    }) => {
                        switch (status) {
                        case 500:
                            break;
                        case 400:
                            break;
                        case 200:
                        default:
                            events.emit(actions.loadModal, { markup });
                        }
                    });
            }
        }

        document.body.addEventListener('click', handleBodyClick);

        if (window.location.hash) {
            const target = document.querySelector(window.location.hash);

            if (target) {
                target.scrollIntoView({ behavior: 'smooth' });
            }
        }

        // Only play videos when they are in view for scroll performance
        const videoObserver = new IntersectionObserver(entries => {
            entries.forEach(({ target, isIntersecting }) => {
                if (isIntersecting) {
                    target.play();
                } else {
                    target.pause();
                }
            });
        }, { threshold: 0.1 });
        Array.from(document.querySelectorAll('video'))
            .filter(v => v.hasAttribute('playsinline'))
            .forEach(v => { videoObserver.observe(v); });

        // Convert oembed tags to embedable content
        document.querySelectorAll('oembed').forEach(o => {
            if (o.matches('[url*="youtube.com"]')) {
                o.insertAdjacentHTML('beforebegin', `
                    <div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;">
                        <iframe src="https://www.youtube.com/embed/${getYoutubeId(o.getAttribute('url'))}"
                            style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;"
                            frameborder="0" allow="autoplay; encrypted-media" allowfullscreen>
                        </iframe>
                    </div>
                `);
            }
        });

        setGclid();
    }

    pop({ classMap, actions, cb });
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', handleDOMConentLoaded);
} else {
    handleDOMConentLoaded();
}
